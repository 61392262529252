#CustomMuiWrapper{
  text-align: center;
  cursor: pointer;
}

#CustomMuiContainer{
  width: 60%;
}

.show>.btn-primary.dropdown-toggle {
  border: 1px solid #e0e0e0;
  background-color: white;
  color: #757575;
}

#dropdown-basic-button {
  width: 100%;
  background-color: #38699E;
  border: 1px solid #38699E;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  position: absolute;
  right: 15px;
  top: 17px;
}