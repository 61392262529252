.timezone-picker {
  display: inline-block;
  font: 13px sans-serif;
  position: relative
}

.timezone-picker-textfield input {
  width: 100%;
  padding: 9px 12px;
  font: inherit;
  box-sizing: border-box;
  outline: 0;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.timezone-picker-list {
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #e6ebec;
  margin-top: -1px;
  border-radius: 0 0 3px 3px;
  display: none
}

.timezone-picker-list-rel {
  position: relative;
}

.timezone-picker-list-abs {
  position: absolute;
  background-color: white;
  z-index: 2000;
  opacity: 0.99;
}

.timezone-picker-list-item {
  color: #444444;
  padding: 5px 12px
}

.timezone-picker-list-item-active {
  background: #ececec;
  cursor: pointer
}

.timezone-picker-open .timezone-picker-list {
  display: block
}

.timezone-picker-selected .timezone-picker-textfield input {
  color: #474747
}