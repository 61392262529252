.MuiFormControl-marginNormal{
  width: 100%;
  background: white;
  border-radius: 4px;
  padding: 4px!important;
  border: 1px solid lightgray!important;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  outline: none!important;
  height: 40px;
}

.MuiFormControl-marginNormal:focus{
  border: 1px solid #38699E!important;
}

.MuiFormHelperText-root{
  display: none!important;
}