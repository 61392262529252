#CustomUserMuiWrapper{
  text-align: center;
  cursor: pointer;
}

#CustomUserContainer{
  width: 50%;
}

.react-pdf__Page__textContent{
  display: none;
}

.ecgPDFwrap{
  display: flex;
  align-items: start;
  flex-wrap: wrap;
}

.ecgBtns{
  margin: -10px 0 0 20px;
}

@media (max-width: 1350px) { 
  .ecgPDFwrap{
    flex-direction: column-reverse;
    display: flex;
    align-items: start;
    flex-wrap: wrap;
  }
  .ecgBtns{
    display: flex;
    margin-left: -10px;
  }
}