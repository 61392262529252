.Toastify__toast-body{
  color: white
}

.Toastify__close-button > svg{
  fill: white
}

div > span {
  color: white
}

.MuiPickersDay-daySelected span {
  color: #ffffff;
}