.stepperTitle{
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #757575;
  margin-bottom: 4px;
}

.separatorLine{
  width: 40px;
  height: 1px;
  background: #E0E0E0;
  padding-left: 5px;
  padding-right: 5px;
}

.subTitle{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #757575
}

@media (max-width: 1024px) {
  .stepperTitle{
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #757575;
    margin-bottom: 4px
  }
  .separatorLine{
    width: 10px;
    height: 1px;
    padding-left: 5px;
  padding-right: 5px;
    background: #E0E0E0;
  }
  .subTitle{
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #757575
  }
}